<template>
    <v-card outlined elevation="3" height="100%">
      <v-card-title class="justify-center card-titel" style="color: white !important">
        <v-img
          :src="require('@/assets/images/logos/wallet.svg')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3 logo"
        ></v-img>
       <span>{{ $vuetify.lang.t('$vuetify.searchContract') }}</span>
      </v-card-title>
      <v-spacer/>

      <!-- Voyage kassandra -->
      <v-card-subtitle v-if="hasTravelSubscribe()" class="card-head full-height pa-2 d-flex justify-space-between card-subtitel">
        <div class="card-subtitel-text">{{ $vuetify.lang.t('$vuetify.travel') }}</div>
        <v-spacer></v-spacer> 
        <v-img
          :src="require('@/assets/images/logos/loupe.svg')"
          max-height="22px"
          max-width="22px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>  
        <div>
          <a :href="this.appConfig.kassandraSearchUrl + token"
          target="_blank"
          style="color: white;">
          {{ $vuetify.lang.t('$vuetify.search') }}
          </a>
        </div>
      </v-card-subtitle>

    
      <br/>
      <v-card-text v-if="hasTravelSubscribe()">
        <div class="padded-kass-contracts">
          <h4 class="font-weight-medium">
            <ul>
              <li v-for="item in data.travel_products.kassandra" v-bind:key="item.id" class="card-body">{{ item.name }}</li>   
            </ul>
          </h4>
        </div>
      </v-card-text>

      <!-- Voyage EP -->
      <v-card-subtitle v-if="hasTravelSubscribe()" class="card-head full-height pa-2 d-flex justify-space-between card-subtitel">
        <div class="card-subtitel-text" >{{ $vuetify.lang.t('$vuetify.travel') }}</div>
        <v-spacer></v-spacer>  
        <v-img
          :src="require('@/assets/images/logos/loupe.svg')"
          max-height="22px"
          max-width="22px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img> 
        <div>
          <a :href="this.appConfig.epSearchUrl + token"
          target="_blank"
          style="color: white;">
          {{ $vuetify.lang.t('$vuetify.search') }}
          </a>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <div class="padded">
          <h4 class="font-weight-medium">
            <ul class="no-style">
              <li v-for="item in data.travel_products.espace_pro" v-bind:key="item.id" class="card-body">{{ item.name }}</li>   
            </ul>
          </h4>
        </div>
      </v-card-text>
    </v-card>

    
  </template>
  
  <script>
  import { mdiDotsVertical, mdiMenuUp } from '@mdi/js'
  
  export default {
    props: {
      data: {
        default: ''
      }
    },
    data() {
      return {
        token: localStorage.getItem("token")
      }
    },
    methods: {
      hasTravelSubscribe() {
      return this.data.travel_subscribe && this.data.travel_subscribe.length > 0;
     }
    },
    setup() {
      return {
        icons: { mdiDotsVertical, mdiMenuUp },
      }
    },
  }
  </script>
  
  <style lang="scss">
    .padded {
      padding-top: 20px;
    }
    .padded-kass-contracts {
      padding-top: 2px;
    }
    .news-box {
      border: 1px solid #004759; 
      color: black; 
      padding: 10px;
      background-color: #e5f6fd; 
      font-size: 0.9em;
    }
  </style>